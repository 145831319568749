import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import CardIconComponent from "../../components/card/CardIconComponent";
import browserHistory from "../../helper/browserHistory";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import BlogArticleCarouselComponent from "../blog/BlogArticleCarouselComponent";
import EditTextComponent from "../../components/moap-components/EditTextComponent";


import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";

const ADDER = 3;

const PAGE_LINK = "Förderung";

@inject("stores")
@observer
class PromotionsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: props.stores.textStore.texts,
            isLoading: true,
            allProjects: [],
            //with sub cat id 1ab4bb83-eb30-4051-af5f-f089144ff190
            peopleProjects: [],
            //with sub cat id df15f587-3ad1-4b8f-85d8-7957183b5fd5
            engagementProjects: [],
            //with sub cat id c2f9e238-8ef8-425d-96ab-49d4944a34bd
            futureProjects: [],
            projectAmount: ADDER
        }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        fetch(config.BASE_URL + 'blog/amount-articles/0', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                // console.log(data)
                let articles = data.articles;

                //Sort Articles
                articles.sort(function (a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a > b ? -1 : a < b ? 1 : 0;
                });
                this.setState({...this.state, allProjects: articles});
                this.divideProjects();

            })
            .catch(function (error) {
                console.log(error);
            });

        window.scrollTo(0, 0);
    }

    divideProjects() {
        let peopleProjects = [];
        let engagementProjects = [];
        let futureProjects = [];
        for (let i = 0; i < this.state.allProjects.length; i++) {
            if (this.state.allProjects[i].categoryIds.includes("1ab4bb83-eb30-4051-af5f-f089144ff190")) {
                peopleProjects.push(this.state.allProjects[i]);
            } else if (this.state.allProjects[i].categoryIds.includes("df15f587-3ad1-4b8f-85d8-7957183b5fd5")) {
                engagementProjects.push(this.state.allProjects[i]);
            } else if (this.state.allProjects[i].categoryIds.includes("c2f9e238-8ef8-425d-96ab-49d4944a34bd")) {
                futureProjects.push(this.state.allProjects[i]);
            }
        }
        this.setState({
            ...this.state,
            peopleProjects: peopleProjects,
            futureProjects: futureProjects,
            engagementProjects: engagementProjects
        });
    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        let firstPeopleProject = null;
        if (this.state.peopleProjects.length > 0) {
            firstPeopleProject = <Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.peopleProjects[0].header}
                    teaserText={this.state.peopleProjects[0].description}
                    imageLink={this.state.peopleProjects[0].thumbnail}
                    buttonLink={this.state.peopleProjects[0].id}
                    dateText={this.state.peopleProjects[0].createdAt}
                    tags={this.state.peopleProjects[0].tags}
                />
            </Grid.Column>
        }

        let firstEngageProject = null;
        if (this.state.engagementProjects.length > 0) {
            firstEngageProject = <Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.engagementProjects[0].header}
                    teaserText={this.state.engagementProjects[0].description}
                    imageLink={this.state.engagementProjects[0].thumbnail}
                    buttonLink={this.state.engagementProjects[0].id}
                    dateText={this.state.engagementProjects[0].createdAt}
                />
            </Grid.Column>
        }


        let firstFutureProject = null;
        if (this.state.futureProjects.length > 0) {
            firstFutureProject = <Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.futureProjects[0].header}
                    teaserText={this.state.futureProjects[0].description}
                    imageLink={this.state.futureProjects[0].thumbnail}
                    buttonLink={this.state.futureProjects[0].id}
                    dateText={this.state.futureProjects[0].createdAt}
                />
            </Grid.Column>
        }

        let showPeopleProjects = [];
        for (let i = 0; i < this.state.peopleProjects.length; i++) {
            if (i === this.state.projectAmount) {
                break;
            }
            showPeopleProjects.push(<Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.peopleProjects[i].header}
                    teaserText={this.state.peopleProjects[i].description}
                    imageLink={this.state.peopleProjects[i].thumbnail}
                    buttonLink={this.state.peopleProjects[i].id}
                    dateText={this.state.peopleProjects[i].createdAt}
                />
            </Grid.Column>);
        }

        let showEngagProjects = [];
        for (let i = 0; i < this.state.engagementProjects.length; i++) {
            if (i === this.state.projectsAmount) {
                break;
            }
            showEngagProjects.push(<Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.engagementProjects[i].header}
                    teaserText={this.state.engagementProjects[i].description}
                    imageLink={this.state.engagementProjects[i].thumbnail}
                    buttonLink={this.state.engagementProjects[i].id}
                    dateText={this.state.engagementProjects[i].createdAt}
                />
            </Grid.Column>);
        }

        let showFutureProjects = [];
        for (let i = 0; i < this.state.futureProjects.length; i++) {
            if (i === this.state.projectsAmount) {
                break;
            }
            showFutureProjects.push(<Grid.Column computer={12} tablet={14} mobile={16}>
                <BlogArticleCardComponent
                    titleText={this.state.futureProjects[i].header}
                    teaserText={this.state.futureProjects[i].description}
                    imageLink={this.state.futureProjects[i].thumbnail}
                    buttonLink={this.state.futureProjects[i].id}
                    dateText={this.state.futureProjects[i].createdAt}
                />
            </Grid.Column>);
        }
        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'promotion'}
                    imageLinkId={"promotion-title-image-id"}
                    headerId={"promotion-title-header-id"}
                    subheaderId={"promotion-subtitle-header-id"}

                />
                <Grid.Row className={"page-row"}>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section
                                className={"active-breadcrumb nav-item-orange"}>FÖRDERUNG</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Förderbereiche</h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={12}>
                        <EditHTMLComponent id={"promotion-subheader-1"} textStore={this.state.textStore}
                                           namespace={'promotion'}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={2} only={'computer'}/>
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'promotion'}
                        iconNameId={"Helpwhite.svg"}
                        headerId={"home-icon-card-1-header-id"}
                        subheaderId={"home-icon-card-1-subheader-id"}
                        color={"green"}
                        buttonTextId={"home-icon-card-1-button-text-id"}
                        buttonLinkId={"home-icon-card-1-button-link-id"}
                        infoTextId={"home-icon-card-1-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Einzelfallhilfe.pdf"}
                        bottomButtonText={"Einzelfallhilfe beantragen"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'promotion'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={"home-icon-card-2-header-id"}
                        subheaderId={"home-icon-card-2-subheader-id"}
                        color={"light-blue"}
                        buttonTextId={"home-icon-card-2-button-text-id"}
                        buttonLinkId={"home-icon-card-2-button-link-id"}
                        infoTextId={"home-icon-card-2-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Projektfoerderung.pdf"}
                        bottomButtonText={"Projektförderung beantragen"}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'promotion'}
                        iconNameId={"Futurewhite.svg"}
                        headerId={"home-icon-card-3-header-id"}
                        subheaderId={"home-icon-card-3-subheader-id"}
                        color={"orange"}
                        buttonTextId={"home-icon-card-3-button-text-id"}
                        buttonLinkId={"home-icon-card-3-button-link-id"}
                        infoTextId={"home-icon-card-3-info-text-id"}
                        bottomButtonLink={"https://www.buergerstiftung-kreis-rv.de/downloads/BSKR_Antrag_Projektfoerderung.pdf"}
                        bottomButtonText={"Projektförderung beantragen"}
                    />

                    <Grid.Column width={2} only={'computer'}/>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                    <h2>Partnerschaft</h2>
                        <EditTextComponent textStore={this.state.textStore} id={"home-partnerschaft-text-1-id"} namespace={'home'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                <Grid.Column computer={4} tablet={8} mobile={14} textAlign={"center"}
                        className={"page-title-button"}>
                        <Button circular fluid className={"call-to-action-button"}
                            onClick={() => {
                                window.open("/partner", "_blank")
                            }}>
                            Unsere Partner
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Menschen in Not</h2>
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row centered>
                    {firstPeopleProject}
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Weitere spannende Projekte - Menschen in Not</h2>
                    </Grid.Column>
                </Grid.Row>*/}
                <Responsive as={Grid.Row} minWidth={992}>
                    <Grid.Column>
                        <Grid>
                            {this.state.peopleProjects.length > 0 ?
                                <BlogArticleCarouselComponent articles={this.state.peopleProjects}/>
                                :
                                null
                            }
                        </Grid>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} centered maxWidth={991}>
                    {showPeopleProjects}
                    {this.state.projectsAmount < this.state.peopleProjects.length ?
                        <Grid.Column width={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        this.state.projectsAmount += ADDER;
                                    }}>
                                MEHR AKTUELLES
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Responsive>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Engagement</h2>
                    </Grid.Column>
                </Grid.Row>
                {/*    <Grid.Row centered>
                    {firstEngageProject}
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Weitere spannende Projekte - Engagement</h2>
                    </Grid.Column>
                </Grid.Row>*/}
                <Responsive as={Grid.Row} minWidth={992}>
                    <Grid.Column>
                        <Grid>
                            {this.state.engagementProjects.length > 0 ?
                                <BlogArticleCarouselComponent articles={this.state.engagementProjects}/>
                                :
                                null
                            }
                        </Grid>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} centered maxWidth={991}>
                    {showEngagProjects}
                    {this.state.projectsAmount < this.state.engagementProjects.length ?
                        <Grid.Column width={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        this.state.projectsAmount += ADDER;
                                    }}>
                                MEHR AKTUELLES
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Responsive>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Unsere Zukunft</h2>
                    </Grid.Column>
                </Grid.Row>
                {/*  <Grid.Row centered>
                    {firstFutureProject}
                </Grid.Row>
                <Grid.Row centered className={"page-row-top"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>Weitere spannende Projekte - Unsere Zukunft</h2>
                    </Grid.Column>
                </Grid.Row>*/}
                <Responsive as={Grid.Row} minWidth={992} className={"page-row"}>
                    <Grid.Column>
                        <Grid>
                            {this.state.futureProjects.length > 0 ?
                                <BlogArticleCarouselComponent articles={this.state.futureProjects}/>
                                :
                                null
                            }
                        </Grid>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} centered maxWidth={991} className={"page-row"}>
                    {showFutureProjects}
                    {this.state.projectsAmount < this.state.futureProjects.length ?
                        <Grid.Column width={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => {
                                        this.state.projectsAmount += ADDER;
                                    }}>
                                MEHR AKTUELLES
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Responsive>
                {/*<ApplyForPromotionComponent imageLinkId={"apply-for-promotion-image-id"}/>*/}
            </Grid>
        );
    }
}

export default PromotionsPage